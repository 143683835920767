
@import url('https://fonts.googleapis.com/css?family=Cabin+Sketch');
h1 {
  font-family: "Cabin Sketch", cursive;
  font-size: 7vw;
  text-shadow: 0px 1px 0px white;
  color: #343434;
  margin-bottom:0;
}

.container {
  position: relative;
  z-index: 0;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

.pulse {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 30rem;
}
.pulse circle {
  fill: #ff5154;
  transform: scale(0);
  opacity: 0;
  transform-origin: 50% 50%;
  animation: pulse 12s 2s cubic-bezier(0.5, 0.5, 0, 1) infinite;
}
.pulse circle:nth-child(2) {
  fill: #7fc6a4;
  animation: pulse 3s 0.75s cubic-bezier(0.5, 0.5, 0, 1) infinite;
}
.pulse circle:nth-child(3) {
  fill: #e5f77d;
  animation: pulse 6s 4s cubic-bezier(0.5, 0.5, 0, 1) infinite;
}

.tag{
text-align:left;
font-size: 3vw;
font-family: "Cabin Sketch", cursive;
}

.tag2{
text-align:left;
font-size: 3vw;
font-family: "Cabin Sketch", cursive;
margin-left:-32px;
color:#ffffff !important;
background:orange;
}

@keyframes pulse {
  25% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
  }
}

/*Sentence*/
.sentence{
     color: #222;
     font-size: 30px;
     text-align: left;
}


/*Vertical Sliding*/
.slidingVertical{
   display: inline;
   text-indent: 8px;
}

.slidingVertical span{
   animation: topToBottom 12.5s linear infinite 0s;
   color: #00abe9;
   opacity: 0;
   overflow: hidden;
   position: absolute;
}
.slidingVertical span:nth-child(2){
   animation-delay: 2.5s;
   -ms-animation-delay: 2.5s;
   -webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3){
   animation-delay: 5s;
   -ms-animation-delay: 5s;
   -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4){
   animation-delay: 7.5s;
   -ms-animation-delay: 7.5s;
   -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5){
   animation-delay: 10s;
   -ms-animation-delay: 10s;
   -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@keyframes topToBottom{
   0% { opacity: 0; }
   5% { opacity: 0; transform: translateY(-50px); }
   10% { opacity: 1; transform: translateY(0px); }
   25% { opacity: 1; transform: translateY(0px); }
   30% { opacity: 0; transform: translateY(50px); }
   80% { opacity: 0; }
   100% { opacity: 0; }
}
